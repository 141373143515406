<template>

<com-list class="links" api="links" :block="block" v-on:loaded="onListLoaded">

	<com-inner>

		<div class="links-text" v-if="!window.is.mobile">Current campaigns</div>

		<div class="links-list">

			<router-link :to="$link(item.url)" class="links-card" v-for="(item, index) in list" :key="index">

				<div class="links-card-image" v-bgimage="item.image" />

				<div class="links-card-name" v-if="!window.is.mobile">{{ item.name }}</div>

			</router-link>

		</div>

	</com-inner>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	}

}

</script>

<style scoped>

.links {
	background-position: 50% 50%;
	background-size: cover;
}

.is-aa .links {
	background-image: url(~@/assets/home.aa.jpg);
}

.is-afa .links {
	background-image: url(~@/assets/home.afa.jpg);
}

.is-stars .links {
	background-image: url(~@/assets/home.stars.jpg);
}

.is-embed .links {
	margin-left: -20px;
	margin-top: -20px;
	margin-bottom: -20px;
	width: calc(100% + 40px);
}

.links-text {
	color: #fff;
	font-size: 16px;
	line-height: 48px;
	padding: 0px 20px;
	margin-top: 20px;
	text-transform: uppercase;
}

.links-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	width: 100%;
	padding: 20px 20px 80px 20px;
}

.is-tablet .links-list {
	padding-bottom: 40px;
}

.is-mobile .links-list {
	padding: 10px 0px;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr;
}

.links-card-image {
	width: 100%;
	height: 218px;
	border-radius: 13px;
	border: 2px solid #fff;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.is-tablet .links-card-image {
	height: 130px;
}

.is-mobile .links-card-image {
	height: 98px;
}

.links-card-name {
	font-size: 24px;
	color: #fff;
	line-height: 30px;
	padding: 10px 0px;
}

.is-tablet .links-card-name {
	font-size: 20px;
	line-height: 24px;
}

</style>
